import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import { SwiperButton } from '@/components/general/SwiperButtons/components';
import { Strings } from '@/constants/strings';
import { getTextColorClass } from '@/utils/getTextColorClass';
import type {
  Background,
  CtaButton,
  ProductImageMap,
  TextColor,
} from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import { type FC, useState } from 'react';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import type { TaggedItemsModalProps } from './components';
import { TaggedItemsModal } from './components';
import { ImageInterestPointsSlide, SplashSlide } from './slideTypes';

export type HeroSlide = {
  type?: 'background' | 'productImageMap';
  title?: string;
  subtitle?: string;
  content?: string;
  contentBackground: Background;
  background: Background;
  defaultTextColor: TextColor;
  actions?: CtaButton[];
  productImageMap?: ProductImageMap<'homeDecor1'>;
};

export type HeroProps = {
  slides?: HeroSlide[];
  bgClass?: string;
  defaultTextColor?: TextColor;
  background?: Background;
  backHref?: string;
  currency?: string;
  autoplay?: boolean;
  autoplayDelay?: number;
};

export const Hero: FC<HeroProps> = ({
  autoplay,
  autoplayDelay,
  slides,
  backHref,
  bgClass,
  background,
  currency = 'CAD',
  defaultTextColor = 'primary',
}) => {
  const [isTaggedItemsModalOpen, setIsTaggedItemsModalOpen] = useState(false);
  const [swiperRealIndex, setSwiperRealIndex] = useState(0);

  if (!slides?.length) return null;

  const defaultTextColorClass = getTextColorClass(defaultTextColor, 'text');
  const hasMultipleSlides = slides?.length > 1;
  const activeProductImageMap = (
    slides[swiperRealIndex] as HeroSlide | undefined
  )?.productImageMap;

  return (
    <>
      <TaggedItemsModal
        title={
          Strings.components.pageSections.hero.taggedItemsModal.title['en-US']
        }
        content={
          Strings.components.pageSections.hero.taggedItemsModal.content['en-US']
        }
        items={activeProductImageMap?.points.reduce(
          (prev, point) => {
            const { product } = point;

            if (!product) return prev;

            const [firstImage] = product.gallery ?? [];

            return prev.concat([
              {
                href: `/products/${product.crmId}${backHref ? `?back=${backHref}` : ''}`,
                image: firstImage,
                name: product.name,
              },
            ]);
          },
          [] as Required<TaggedItemsModalProps>['items']
        )}
        open={isTaggedItemsModalOpen}
        onClose={() => setIsTaggedItemsModalOpen(false)}
      />

      <header className={clsx([bgClass, defaultTextColorClass])}>
        <BackgroundWrapper background={background}>
          <Swiper
            modules={[Navigation, Autoplay]}
            loop
            className="relative"
            // Added autoplay
            autoplay={
              autoplay && {
                delay: autoplayDelay,
                disableOnInteraction: false,
              }
            }
            onRealIndexChange={(swiper) => setSwiperRealIndex(swiper.realIndex)}
          >
            {slides.map((slide, index) => {
              const { type } = slide;

              // To support backwards compatibility where type is not defined,
              // we default to background type
              if (!type)
                return (
                  <SwiperSlide key={index}>
                    <SplashSlide
                      slide={slide}
                      hasMultipleSlides={hasMultipleSlides}
                    />
                  </SwiperSlide>
                );

              switch (type) {
                case 'background': {
                  return (
                    <SwiperSlide key={index}>
                      <SplashSlide
                        slide={slide}
                        hasMultipleSlides={hasMultipleSlides}
                      />
                    </SwiperSlide>
                  );
                }
                case 'productImageMap': {
                  return (
                    <SwiperSlide key={index}>
                      <ImageInterestPointsSlide
                        slide={slide}
                        backHref={backHref}
                        currency={currency}
                        hasMultipleSlides={hasMultipleSlides}
                        onTaggedItemsModalOpen={() =>
                          setIsTaggedItemsModalOpen(true)
                        }
                      />
                    </SwiperSlide>
                  );
                }
                default: {
                  return null;
                }
              }
            })}

            {hasMultipleSlides && (
              <div
                className={clsx([
                  '-mt-22',
                  'mx-auto',
                  'max-w-lg',
                  'px-10',
                  'flex',
                  'justify-end',
                  'gap-20',
                  'relative',
                  'z-10',
                ])}
              >
                <SwiperButton
                  slideDirection="prev"
                  className={clsx(['aspect-square', '!p-12', 'lg:!p-12'])}
                />

                <SwiperButton
                  slideDirection="next"
                  className={clsx(['aspect-square', '!p-12', 'lg:!p-12'])}
                />
              </div>
            )}
          </Swiper>
        </BackgroundWrapper>
      </header>
    </>
  );
};
