import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import { CtaButton } from '@/components/general/CtaButton';
import { getCtaButtonVariant } from '@/utils/getCtaButtonVariant';
import { getTextColorClass } from '@/utils/getTextColorClass';
import clsx from 'clsx';
import type { FC } from 'react';
import { useIsClient } from 'usehooks-ts';

import type { HeroSlide } from '../../Hero';

export type SplashSlideProps = {
  slide: HeroSlide;
  hasMultipleSlides?: boolean;
};

export const SplashSlide: FC<SplashSlideProps> = ({
  hasMultipleSlides,
  slide,
}) => {
  const isClient = useIsClient();
  const {
    actions,
    contentBackground,
    background: slideBackground,
    defaultTextColor: slideDefaultTextColor,
    content = '',
    subtitle = '',
    title = '',
  } = slide;
  const slideDefaultTextColorClass = getTextColorClass(
    slideDefaultTextColor,
    'text'
  );

  return (
    <>
      {!!(isClient && title) && (
        <h2 className="sr-only" dangerouslySetInnerHTML={{ __html: title }} />
      )}

      <BackgroundWrapper background={slideBackground}>
        <div
          className={clsx([
            slideDefaultTextColorClass,
            'px-16',
            'py-40',
            'text-center',
            'flex',
            'w-full',
            'items-center',
            'justify-center',
            'bg-cover',
            'bg-center',
            { 'h-[90vh]': !hasMultipleSlides },
            { 'h-[calc(90vh-22px)]': hasMultipleSlides },
          ])}
        >
          <BackgroundWrapper
            background={contentBackground}
            containerProps={{
              className:
                'flex flex flex-col items-center justify-center px-12 py-20 lg:p-40',
            }}
          >
            {!!(isClient && title) && (
              <h2
                className="mb-20 text-center text-40 lg:text-start lg:text-52"
                dangerouslySetInnerHTML={{ __html: title }}
              />
            )}

            {!!(isClient && subtitle) && (
              <div
                className="mb-40 lg:text-18"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}

            {!!(isClient && content) && (
              <div
                className={clsx([
                  'text-16',
                  'lg:text-18',
                  'text-center',
                  'lg:text-start',
                ])}
                dangerouslySetInnerHTML={{ __html: content }}
              />
            )}

            {!!(isClient && actions?.length) && (
              <div className="flex flex-col items-center justify-center gap-5 lg:flex-row">
                {actions?.map((action, actionIndex) => (
                  <CtaButton
                    className="page-section-hero-slide-action-button"
                    key={actionIndex}
                    type={action.type}
                    variant={getCtaButtonVariant(action)}
                    action={
                      action.type === 'action' ? action.action : undefined
                    }
                    url={
                      action.type !== 'action' && action.type !== 'none'
                        ? action.href
                        : undefined
                    }
                  >
                    {action.label}
                  </CtaButton>
                ))}
              </div>
            )}
          </BackgroundWrapper>
        </div>
      </BackgroundWrapper>
    </>
  );
};
