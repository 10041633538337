import { BackgroundWrapper } from '@/components/BackgroundWrapper';
import type { ImageInterestPointsProps } from '@/components/ImageInterestPoints';
import { ImageInterestPoints } from '@/components/ImageInterestPoints';
import { CtaButton } from '@/components/general/CtaButton';
import { getCtaButtonVariant } from '@/utils/getCtaButtonVariant';
import { getTextColorClass } from '@/utils/getTextColorClass';
import { formatCurrency } from '@/utils/stringFormat';
import type { Product } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';
import { useState } from 'react';
import { BsBag } from 'react-icons/bs';
import { MdList } from 'react-icons/md';
import { useIsClient } from 'usehooks-ts';

import type { HeroSlide } from '../../Hero';
import { ImageInterestPointCard } from '../../components/ImageInterestPointCard';

type ImageInterestPointButtonIconProps = {
  className?: string;
  color?: string;
};

const ImageInterestPointButtonIcon: FC<ImageInterestPointButtonIconProps> = ({
  className,
  color,
}) => {
  return (
    <BsBag
      className={clsx(['size-14', 'transition', 'lg:size-20', className])}
      color={color}
    />
  );
};

export type ImageInterestPointsSlideProps = {
  backHref?: string;
  currency?: string;
  hasMultipleSlides?: boolean;
  slide: HeroSlide;
  onTaggedItemsModalOpen?: () => void;
};

export const ImageInterestPointsSlide: FC<ImageInterestPointsSlideProps> = ({
  backHref,
  currency,
  hasMultipleSlides,
  onTaggedItemsModalOpen,
  slide,
}) => {
  const isClient = useIsClient();
  const {
    productImageMap,
    actions,
    contentBackground,
    defaultTextColor: slideDefaultTextColor,
    content = '',
    subtitle = '',
    title = '',
  } = slide;
  const [selectedImageInterestPoint, setSelectedImageInterestPoint] =
    useState('');
  const [
    isSelectedImageInterestPointCardOpen,
    setIsSelectedImageInterestPointCardOpen,
  ] = useState(false);

  const slideDefaultTextColorClass = getTextColorClass(
    slideDefaultTextColor,
    'text'
  );

  if (!productImageMap) return null;

  const selectedImageInterestPointIndex = Number.parseInt(
    selectedImageInterestPoint,
    10
  );
  const activePoint = productImageMap.points[
    selectedImageInterestPointIndex
  ] as (typeof productImageMap.points)[number] | undefined;
  const activeProduct = activePoint?.product as
    | Product<'homeDecor1'>
    | undefined;
  const activeProductHref = activeProduct
    ? `/products/${activeProduct.crmId}${backHref ? `?back=${backHref}` : ''}`
    : '#';
  const [activeProductImage] = activeProduct?.gallery ?? [];

  return (
    <div
      className={clsx([
        slideDefaultTextColorClass,
        'overflow-hidden',
        'relative',
        { 'h-[90vh]': !hasMultipleSlides },
        { 'h-[calc(90vh-22px)]': hasMultipleSlides },
      ])}
    >
      <ImageInterestPoints
        containerClassName={clsx([
          'w-max',
          'h-full',
          'left-1/2',
          '-translate-x-1/2',
          '[@media(min-width:1400px)]:w-full',
        ])}
        image={productImageMap.image}
        buttonColors={productImageMap.buttonColors.background}
        onPointClick={(pointValue) => {
          if (pointValue === selectedImageInterestPoint) {
            setIsSelectedImageInterestPointCardOpen((prev) => !prev);
          } else {
            setIsSelectedImageInterestPointCardOpen(true);
          }

          setSelectedImageInterestPoint(pointValue);
        }}
        pointValue={selectedImageInterestPoint}
        points={productImageMap.points.reduce(
          (prev, point, pointIndex) => {
            const { product } = point;

            if (!product) return prev;

            return prev.concat([
              {
                colors: point.colors.background,
                position: point.position,
                title: product.name,
                value: `${pointIndex}`,
                Icon: ({ selected }) => {
                  const buttonInactiveIconClass =
                    productImageMap.buttonColors.icon.unselected === 'light'
                      ? 'text-white'
                      : 'text-primary';
                  const buttonActiveIconClass =
                    productImageMap.buttonColors.icon.selected === 'light'
                      ? 'text-white'
                      : 'text-primary';
                  const iconClass = selected
                    ? buttonActiveIconClass
                    : buttonInactiveIconClass;
                  const iconColor =
                    (selected
                      ? point.colors?.icon?.selected
                      : point.colors?.icon?.unselected) || undefined;

                  return (
                    <ImageInterestPointButtonIcon
                      className={iconClass}
                      color={iconColor}
                    />
                  );
                },
              },
            ]);
          },
          [] as Required<ImageInterestPointsProps>['points']
        )}
      />

      {/* Desktop interest point card */}
      <div
        className={clsx([
          'w-full',
          'max-w-[345px]',
          'md:max-w-[560px]',
          'lg:max-w-[345px]',
          'absolute',
          'transition-all',
          'hidden',
          'lg:block',
          'top-1/2',
          '-translate-y-1/2',
          !isSelectedImageInterestPointCardOpen && [
            'opacity-0',
            'right-0',
            'pointer-events-none',
          ],
          isSelectedImageInterestPointCardOpen && ['opacity-100', 'right-30'],
        ])}
        // style={
        //   activePoint
        //     ? {
        //         top: `calc(${activePoint.position.top}% - 40px)`,
        //         left: `calc(${
        //           isSelectedImageInterestPointCardOpen
        //             ? `${activePoint.position.left}%`
        //             : `${activePoint.position.left + 10}%`
        //         } + 40px)`,
        //       }
        //     : {
        //         left: '100%',
        //       }
        // }
      >
        <ImageInterestPointCard
          onClose={() => setIsSelectedImageInterestPointCardOpen(false)}
          image={activeProductImage}
          href={activeProductHref}
          orientation={{ desktop: 'column' }}
        >
          {!!activeProduct && (
            <div
              className={clsx(
                'w-full',
                'p-24',
                'text-center',
                'text-14',
                'lg:text-16'
              )}
            >
              <div>
                <p>{activeProduct?.name}</p>

                <p>
                  {formatCurrency(activeProduct.value, {
                    options: { currency },
                  })}
                </p>
              </div>
            </div>
          )}
        </ImageInterestPointCard>
      </div>

      {/* Mobile interest point card */}
      <div
        className={clsx([
          'w-[90%]',
          'max-w-[345px]',
          'md:max-w-[560px]',
          'lg:max-w-[345px]',
          'absolute',
          'transition-all',
          'lg:hidden',
          'left-1/2',
          '-translate-x-1/2',
          '-translate-y-1/2',
          !isSelectedImageInterestPointCardOpen && [
            'opacity-0',
            'top-full',
            'pointer-events-none',
          ],
          isSelectedImageInterestPointCardOpen && ['opacity-100', 'top-1/2'],
        ])}
      >
        <ImageInterestPointCard
          onClose={() => setIsSelectedImageInterestPointCardOpen(false)}
          image={activeProductImage}
          href={
            activeProduct
              ? `/products/${activeProduct.crmId}${backHref ? `?back=${backHref}` : ''}`
              : '#'
          }
          orientation={{
            mobile: 'column',
            desktop: 'column',
          }}
        >
          {!!activeProduct && (
            <div
              className={clsx(
                'w-full',
                'p-24',
                'text-center',
                'text-14',
                'lg:text-16'
              )}
            >
              <div>
                <p>{activeProduct?.name}</p>

                <p>
                  {formatCurrency(activeProduct.value, {
                    options: { currency },
                  })}
                </p>
              </div>
            </div>
          )}
        </ImageInterestPointCard>
      </div>

      {/* Show all tags button */}
      {!!slide.productImageMap?.points.length && (
        <button
          title="Show all tagged items"
          className={clsx([
            'group',
            'rounded-full',
            'absolute',
            'bottom-10',
            'left-10',
            'lg:bottom-20',
            'lg:left-20',
            'p-[4px]',
            'transition-all',
            'shadow-md',
            'border',
            'bg-button-secondary-bg',
            'border-button-secondary-border',
            'hover:bg-button-secondary-hover-bg',
            'hover:border-button-secondary-hover-border',
          ])}
          onClick={onTaggedItemsModalOpen}
        >
          <MdList
            className={clsx([
              'text-button-secondary-text',
              'group-hover:text-button-secondary-hover-text',
            ])}
            size={20}
          />
        </button>
      )}

      <BackgroundWrapper
        background={contentBackground}
        containerProps={{
          className: clsx([
            'flex',
            'flex',
            'flex-col',
            'items-center',
            'justify-center',
            'px-12',
            'py-20',
            'lg:p-40',
          ]),
        }}
        wrapperProps={{
          className: clsx([
            '!absolute',
            'top-1/2',
            'left-1/2',
            '-translate-x-1/2',
            '-translate-y-1/2',
            'page-section-hero-slide-wrapper-props',
          ]),
        }}
      >
        {!!(isClient && title) && (
          <h2
            className="mb-20 text-center text-40 lg:text-start lg:text-52"
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}

        {!!(isClient && subtitle) && (
          <div
            className="mb-40 lg:text-18"
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}

        {!!(isClient && content) && (
          <div
            className={clsx([
              'text-16',
              'lg:text-18',
              'text-center',
              'lg:text-start',
              'mb-40',
            ])}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        )}

        {!!(isClient && actions?.length) && (
          <div className="flex flex-col items-center justify-center gap-5 lg:flex-row">
            {actions?.map((action, actionIndex) => (
              <CtaButton
                key={actionIndex}
                variant={getCtaButtonVariant(action)}
                className="page-section-hero-slide-action-button"
                type={action.type}
                action={action.type === 'action' ? action.action : undefined}
                url={
                  action.type !== 'action' && action.type !== 'none'
                    ? action.href
                    : undefined
                }
              >
                {action.label}
              </CtaButton>
            ))}
          </div>
        )}
      </BackgroundWrapper>
    </div>
  );
};
