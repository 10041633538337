import { CloseButton } from '@/components/general/CloseButton';
import { MissingImage } from '@/components/general/MissingImage';
import { Modal } from '@/components/general/Modal';
import type { ParsedImage } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';

export type TaggedItemsModalProps = {
  title?: string;
  content?: string;
  items?: {
    name: string;
    image?: ParsedImage;
    href?: string;
  }[];
  open?: boolean;
  onClose?: () => void;
};

export const TaggedItemsModal: FC<TaggedItemsModalProps> = ({
  items,
  content,
  onClose,
  open,
  title,
}) => {
  return (
    <Modal open={open} onOutsideClick={onClose}>
      <div
        className={clsx([
          'relative',
          'p-18',
          'border',
          'border-primary',
          'bg-background-primary/85',
          'backdrop-blur-md',
          'rounded-lg',
          'text-text-primary',
          'flex',
          'flex-col',
          'gap-12',
          'max-h-[80vh]',
          'w-[90%]',
          'mx-auto',
          'max-w-md',
        ])}
      >
        <CloseButton
          onClick={onClose}
          className={clsx(['absolute', 'top-[-12px]', 'right-[-12px]'])}
        />

        <div className={clsx(['flex', 'flex-col', 'gap-8'])}>
          {!!title && (
            <h2 className={clsx(['text-header-2-mob lg:text-header-2'])}>
              {title}
            </h2>
          )}

          {!!content && <p className={clsx(['text-sm'])}>{content}</p>}
        </div>

        {!!items?.length && (
          <ul
            className={clsx([
              'flex',
              'flex-col',
              'list-none',
              'm-0',
              'p-0',
              'gap-18',
              'overflow-y-auto',
              'text-20',
              'text-primary',
            ])}
          >
            {items.map((item, index) => {
              return (
                <li
                  key={`${index}-${item.name}`}
                  className={clsx(['hover:bg-secondary', 'transition-all'])}
                >
                  <a
                    href={item.href || '#'}
                    className={clsx(['flex', 'gap-12', 'items-center'])}
                  >
                    <div
                      className={clsx([
                        'self-start',
                        'size-full',
                        'max-w-80',
                        'aspect-square',
                      ])}
                    >
                      {!!item.image && (
                        <img
                          src={item.image.src}
                          alt={item.image.alt || item.name}
                          className={clsx([
                            'size-full',
                            'object-cover',
                            'object-center',
                          ])}
                          loading="lazy"
                        />
                      )}

                      {!item.image && <MissingImage />}
                    </div>

                    <p>{item.name}</p>
                  </a>
                </li>
              );
            })}
          </ul>
        )}

        {!items?.length && <p>No tagged items found.</p>}
      </div>
    </Modal>
  );
};
